import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator'
import { PrestationReportDataModel } from '@/models/prescripton-report-model'
import { reportType } from '@/views/Patients/Prescriptions/Constants'
import { AppUser } from '@/models/app-user-dto'
import Commons from '@/components/shared/Helpers/commons'
import DocPreviewBase from '../DocPreviewBase.vue'

@Component({
  components: {
    DocPreviewBase
  }
})
export default class IntermediateFinalReport extends Vue {
  @Prop({ default: {} })
  public report!: PrestationReportDataModel

  @Prop({ default: null })
  public infirmiere!: AppUser | null

  @Prop({ default: null })
  public medecin!: any

  @Prop({ default: null })
  public currentUser!: AppUser | null

  public titre = "Docteur,"

  get reportType () {
    return reportType.find(t => t.value === this.report.typeId)?.text
  }

  get formattedText () {
    if (!this.report?.text) {
      return ''
    }
    return Commons.newlines2br(this.report.text)
  }

  get formattedTitre () {
    return Commons.newlines2br(this.titre)
  }
}

import { Component, Vue } from 'vue-property-decorator'
import DocumentPreview from '@/components/shared/DocumentPreview/IntermediateFinalReport/IntermediateFinalReport.vue'
import { PatientPrescriptionService } from '@/services/patient-prescription-service'
import { PrestationReportDataModel } from '@/models/prescripton-report-model'
import Commons from '@/components/shared/Helpers/commons'
import { AuthService } from '@/services/auth-service'
import { AppUser } from '@/models/app-user-dto'
import { dossierPatientModel } from '@/models/dossier-response-model'
import ReportHelpers from '@/components/shared/Helpers/ReportHelpers'
import Alert from '@/components/shared/Alert.vue'

@Component({
  components: {
    Alert,
    DocumentPreview
  }
})
export default class Report extends Vue {
  private prescriptionService = PatientPrescriptionService.getInstance()
  private userService = AuthService.getInstance()
  public report: PrestationReportDataModel = {}
  public errorMessages: string[] = []
  public theInfirmiere: AppUser | null = null
  public theMedecin: any = null
  public currentUser: AppUser | null = null
  public reseauPro: any[] = []
  private selectedDossier!: dossierPatientModel
  public loading = false
  public saving = false

  public async mounted () {
    this.loading = true
    this.saving = false
    Commons.updateDossierName()
    this.report = await this.prescriptionService.GetPrescriptionReportData(this.$route.params.id)
    this.loading = false
    if (this.report.nurseId) {
      const allInfirmieres = await this.userService.getAllInfirmiereGroupUsers()
      const found = Commons.FindNurse(allInfirmieres, this.report.nurseId)
      if (found) {
        this.theInfirmiere = found
      }
    }
    if (this.report.doctorId) {
      const cachedDossier = sessionStorage.getItem('selectedDossier')
      if (cachedDossier) {
        this.selectedDossier = JSON.parse(cachedDossier)
        if (this.selectedDossier.guid) {
          await Commons.getDossierReseau(this.selectedDossier.guid, this.errorMessages, this.reseauPro)
          this.theMedecin = this.reseauPro.find(x => x.reseauMedicalExtra?.reseauId === this.report.doctorId)
        }
      }
    }

    this.currentUser = await this.userService.getAppUser(this.errorMessages)
  }

  public async savePDF () {
    this.saving = true
    const documentPreview = this.$refs.documentpreview as any
    const model = ReportHelpers.GeneratePDFModel(this.report.typeId!,
      `Rapport ${(this.report.typeId === 1 ? "Intermédiaire" : "Final")}-${this.report.prenom} ${this.report.nom}-${Commons.TransformDateFormat(this.report.reportDate!)}.pdf`,
      documentPreview, this.report.prescriptionId!, this.theMedecin?.fullname, undefined)
    if (this.selectedDossier.guid) {
      await ReportHelpers.UploadDocument(model, this.errorMessages, this.$router, this.selectedDossier.guid)
    }
    this.saving = false
  }

  public goBack () {
    this.$router.push({ name: 'PrestationReports', params: { id: this.report.prestationId! } })
  }

  public hideAlert () {
    this.errorMessages = []
  }
}
